@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
  z-index: 10000000;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

#groupDomainsTable {
  width: 100%;
}

#groupDomainsTable tr {
  border-bottom: 1px solid #d7cfcf;
}

#groupDomainsTable tr:hover {
  background-color: #eee;
}

#groupDomainsTable thead th {
  padding: 15px 10px;
  color: #747D86;
  background-color: #F1F5F7;
  text-align: center;
  font-size: 13px;
}

#groupDomainsTable tbody td {
  padding: 8px 10px;
  line-height: 29px;
  color: #353C44;
  text-align: center;
  font-size: 13px;
}

#domainsTable {
  width: 100%;
}

#domainsTable thead th {
  padding: 15px 20px;
  color: #747D86;
  background-color: #F1F5F7;
  text-align: center;
}

#domainsTable tbody td {
  padding: 15px 20px;
  color: #353C44;
}

.rbc-event {
  background-color: #FF5C8E!important;
}

#year-view.year {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

#year-view .month {
  margin: 5px 5px 15px 5px;
}

#year-view .month-name {
  color: #03c9d7;
  font-weight: bold;
}

#year-view .day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

#year-view .date {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50px;
  border: none;
  outline: none;
  margin: 2px;
}

/* #year-view .date.in-month:hover {
  cursor: pointer;
  background: #03c9d7;
} */

/* #year-view .today, */
#year-view .disconnected {
  background: #FF5C8E;
  color: #ffffff;
}

#year-view .date.prev-month,
#year-view .date.next-month {
  color: grey;
}

@media (max-width: 1700px) {
  #year-view.year {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1440px) {
  #year-view.year {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1200px) {
  #year-view.year {
    grid-template-columns: repeat(2, 1fr);
  }
}